import {
	createAuthStore,
	type IAuthStoreConfig,
} from '@automation-analytics/component-library';

import { getLogin, postLogout } from 'services/api';
import { paths, APP_SHORT_NAME } from 'enums';
import { userSchema } from 'types/schemas';
import { setUserId } from 'services/NewRelic';
import type { User } from 'types';

const config: IAuthStoreConfig<User> = {
	storeKey: `${APP_SHORT_NAME}-session`,
	userSchema,
	onLogin: () =>
		getLogin()
			.then(({ data: { loginUrl } }) => {
				window.location.href = loginUrl;
			})
			.catch((e) => {
				return Promise.reject(e);
			}),
	onLogout: (slo: boolean) =>
		postLogout(slo)
			.then(({ data: { ssoLogoutUrl } }) => {
				window.location.href = ssoLogoutUrl;
				return Promise.resolve();
			})
			.catch((e) => {
				window.location.href = paths.root;
				return Promise.reject(e);
			}),
	onMount: ({ session, isAuthenticated }) => {
		if (!isAuthenticated()) {
			setUserId(null);
		} else {
			setUserId(session.user!.email);
		}
	},
};

const { useAuth, AuthProvider } = createAuthStore<User>(config);

export { useAuth, AuthProvider };

import axios from 'axios';
import { forIn, kebabCase } from 'lodash';

import Endpoints from './Endpoints';
import { auxiliaryClient, client } from './clients';
import type {
	SSOLoginResponse,
	BackendDeploymentDetails,
	User,
	FileStructureResponse,
	InitiateUploadResponse,
	LogoutPayload,
	MyFilesPayload,
	TaskInfo,
	UploadMetaData,
} from 'types';

// "under-the-hood" requests (use 'auxiliaryClient')
export const getLogin = () => {
	return auxiliaryClient.get<SSOLoginResponse>(Endpoints.oidcLogin, {
		withCredentials: false,
	});
};

export const postRefresh = () => {
	return auxiliaryClient.post(Endpoints.oidcRefresh);
};

export const postLogout = (slo: boolean = false) => {
	return auxiliaryClient.post<LogoutPayload>(
		Endpoints.oidcLogout(slo ? 'yes' : 'no')
	);
};

export const getUser = () => {
	return client.get<{ user: User }>(Endpoints.user);
};

export const initiateUpload = (
	fileName: string,
	caseNumber: string,
	customerId: string
) => {
	return client.post<InitiateUploadResponse>(Endpoints.upload, {
		filename: fileName,
		caseNumber,
		customerId,
	});
};

export const processFile = (uploadId: string, filename: string) => {
	return client.post<TaskInfo>(Endpoints.complete, {
		uploadId,
		filename,
	});
};

export const uploadFile = (
	signedUploadUrl: string,
	metadata: UploadMetaData,
	file: File
) => {
	const data = new FormData();
	forIn(metadata, (value, key) => {
		data.append(kebabCase(key), value);
	});
	data.append('file', file);
	return axios.post(signedUploadUrl, data, {
		headers: {
			'Content-Type': file.type,
		},
	});
};

export const getFileStructure = (uploadId: string) => {
	return client.get<FileStructureResponse>(Endpoints.fileStructure(uploadId));
};

export const getZipOverview = (uploadId: string) => {
	return client.get(Endpoints.zipOverview(uploadId));
};

export const deleteZip = (uploadId: string) => {
	return client.delete(Endpoints.deleteContent(uploadId));
};

export const getMyFiles = () => {
	return client.get<MyFilesPayload>(Endpoints.myFiles);
};

export const getBackendDeploymentDetails = () => {
	return client.get<BackendDeploymentDetails>(
		Endpoints.backendDeploymentDetails
	);
};

export const getFileContent = (uploadId: string, filename: string) => {
	return client.get(Endpoints.fileContent(uploadId, filename));
};

// allows us to check urls at compile time for correct formatting
type ApiUrl = `/${string}/` | `/${string}/?${string}`;
type ApiUrls = Record<string, ApiUrl | ((...args: string[]) => ApiUrl)>;

const Endpoints = {
	// auth
	oidcLogin: '/oidc/login/', // no-creds
	oidcLogout: (slo: string): ApiUrl => `/oidc/logout/?slo=${slo}`, // creds
	oidcRefresh: '/oidc/refresh/', // creds

	// user
	user: '/api/v1/me/',
	upload: '/api/v1/upload/initiate/',
	complete: '/api/v1/upload/complete/',

	// content
	zipMetadata: (uploadId: string): ApiUrl =>
		`/api/v1/content/${uploadId}/zip/`, // get the zip file metadata
	zipContent: (uploadId: string): ApiUrl =>
		`/api/v1/content/${uploadId}/zip/content/`, // get the entire zip file content
	fileMetadata: (uploadId: string, filename: string): ApiUrl =>
		`/api/v1/content/${uploadId}/zip/file/${filename}/`, // get the file's metadata
	fileContent: (uploadId: string, filename: string): ApiUrl =>
		`/api/v1/content/${uploadId}/zip/file/${encodeURIComponent(
			filename
		)}/content/`, // get the file's content
	fileStructure: (uploadId: string): ApiUrl =>
		`/api/v1/content/${uploadId}/file-structure/`,
	zipOverview: (uploadId: string): ApiUrl =>
		`/api/v1/content/${uploadId}/overview/`,
	parsingStatus: (uploadId: string): ApiUrl =>
		`/api/v1/content/${uploadId}/status/`,
	deleteContent: (uploadId: string): ApiUrl =>
		`/api/v1/content/${uploadId}/delete/`,
	myFiles: '/api/v1/content/my-files/',

	// meta
	backendDeploymentDetails: `/api/v1/deployment-details/`,
} satisfies ApiUrls;

export { type ApiUrl };
export default Endpoints;
